import styled from 'styled-components';

export const Details = styled.div`
  margin-top: 50px;
`;

export const GalleryContainer = styled.div``;

export const ShareAPIButtonContainer = styled.div`
  margin-bottom: 15px;
`;

export const SubHeadingDate = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 10px 0 20px;
`;

export const SubHeadingDateYear = styled.span`
  font-weight: 600;
`;

export const SubHeadingDateTitle = styled.span`
  @media screen and (max-width: 600px) {
    display: block;
    text-align: center;
  }
`;

export const HyvorContainer = styled.div`
  margin-top: 10px;
`;
