import React, { useState } from 'react';
import { PageProps, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import moment from 'moment';
import 'moment/locale/sv';
import { Embed } from 'hyvor-talk-react';

import { Shell } from '@components/Shell/Shell';
import { ExhibitionEnhanced } from '@src/types/Exhibition';
import { SEO } from '@src/components/SEO/SEO';
import { Content } from '@src/components/Content/Content';
import { Heading } from '@src/components/Heading/Heading';
import { useWhichLanguage } from '@src/hooks/useWhichLanguage';
import { Breadcrumbs } from '@src/components/Breadcrumbs/Breadcrumbs';
import { Routes } from '@src/constants/routes';
import { MediaModal } from '@src/components/MediaModal/MediaModal';
import { MediaModalImage, MediaModalVideo } from '@src/types/media';
import { InfoSection } from '@src/components/InfoSection/InfoSection';
import nl2br from 'react-nl2br';

import * as S from './Exhibition.S';
import { Gallery } from '@src/components/Gallery/Gallery';
import { youtubeVideoIdSeparator } from '@src/constants/video';
import { YoutubeVideoID } from '@src/types/aliases';
import { GalleryItemSuperType } from '@src/types/gallery';
import { Button } from '@src/components/Button/Button';
import { gtagEvents } from '@src/constants/gtagEvents';
import {
  HYVOR_TALK_ID_PREFIX,
  HYVOR_TALK_WEBSITE_ID,
} from '@src/constants/hyvor';

const ExhibitionPage: React.FC<
  PageProps & {
    data: {
      api: {
        exhibition: ExhibitionEnhanced | null;
      };
      site: {
        siteMetadata: {
          siteUrl: string;
        };
      };
    };
  }
> = ({
  data: {
    api: { exhibition },
    site: {
      siteMetadata: { siteUrl },
    },
  },
  location,
}) => {
  const { t, language } = useI18next();
  const { isEnglish } = useWhichLanguage();
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (
    !exhibition ||
    !exhibition.largeImagePathSharp ||
    !exhibition.jumboImagePathSharp
  ) {
    return null;
  }

  const openMediaModal = () => {
    setIsMediaModalOpen(true);
  };

  const closeMediaModal = () => {
    setIsMediaModalOpen(false);
  };

  const {
    jumboImagePathSharp,
    enTitle,
    svTitle,
    svBody,
    enBody,
    enTimeDate,
    svTimeDate,
    locationAnchorLink,
    locationAnchorText,
    exhibitionWebsiteAnchorLink,
    exhibitionWebsiteAnchorText,
    youtubeVideoIds,
    startAt,
    endAt,
  } = exhibition;

  const renderDate = () => {
    const momentStartAt = moment(startAt, undefined, language);
    const momentEndAt = moment(endAt, undefined, language);
    const dayStartAt = momentStartAt.format('DD');
    const dayEndAt = momentEndAt.format('DD');
    const monthStartAt = momentStartAt.format('MMMM');
    const monthEndAt = momentEndAt.format('MMMM');
    const yearStartAt = momentStartAt.format('YYYY');
    const yearEndAt = momentEndAt.format('YYYY');
    return (
      <span>
        <S.SubHeadingDateTitle>{t('ExhibitionDates')}:</S.SubHeadingDateTitle>{' '}
        {momentStartAt.isSame(momentEndAt, 'day') ? (
          <>
            {`${dayStartAt} ${monthStartAt}`}{' '}
            <S.SubHeadingDateYear>{yearStartAt}</S.SubHeadingDateYear>
          </>
        ) : momentStartAt.isSame(momentEndAt, 'year') ? (
          momentStartAt.isSame(momentEndAt, 'month') ? (
            <>
              {`${dayStartAt} - ${dayEndAt} ${monthStartAt}`}{' '}
              <S.SubHeadingDateYear>{yearStartAt}</S.SubHeadingDateYear>
            </>
          ) : (
            <>
              {`${dayStartAt} ${monthStartAt} - ${dayEndAt} ${monthEndAt}`}{' '}
              <S.SubHeadingDateYear>{yearStartAt}</S.SubHeadingDateYear>
            </>
          )
        ) : (
          <>
            {`${dayStartAt} ${monthStartAt}`}{' '}
            <S.SubHeadingDateYear>{yearStartAt}</S.SubHeadingDateYear> -{' '}
            {`${dayEndAt} ${monthEndAt}`}{' '}
            <S.SubHeadingDateYear>{yearEndAt}</S.SubHeadingDateYear>
          </>
        )}
      </span>
    );
  };

  const youtubeVideoIDsArray =
    youtubeVideoIds.trim() === ''
      ? []
      : youtubeVideoIds
          .trim()
          .split(youtubeVideoIdSeparator)
          .map((youtubeVideoID: YoutubeVideoID) => youtubeVideoID);

  const image = getImage(jumboImagePathSharp.childImageSharp);
  const title = isEnglish ? enTitle : svTitle;
  const bodyText = isEnglish ? enBody : svBody;

  const jumboClick = () => {
    setCurrentImageIndex(0);
    openMediaModal();
  };

  const modalImages = [
    exhibition,
    ...exhibition.additionalExhibitionImages,
  ].map((entity): MediaModalImage => {
    const image = getImage(entity.largeImagePathSharp?.childImageSharp || null);
    return {
      path: entity.imagePath,
      imageElement: image ? (
        <GatsbyImage image={image} objectFit="contain" alt="" />
      ) : (
        <></>
      ),
      description:
        'exhibitionID' in entity
          ? isEnglish
            ? entity.enDescription
            : entity.svDescription
          : '',
    };
  });

  const modalVideos = youtubeVideoIDsArray.map(
    (youtubeVideoID): MediaModalVideo => {
      return {
        youtubeVideoID,
      };
    }
  );

  const modalMediaItems = [...modalImages, ...modalVideos];

  const videoGalleryItems = youtubeVideoIDsArray.map(
    (videoID): GalleryItemSuperType => ({
      id: videoID,
      youtubeVideoID: videoID,
      pixelWidth: 320,
    })
  );

  const imageGalleryItems = exhibition.additionalExhibitionImages.map(
    (image): GalleryItemSuperType => {
      const { id, thumbnailImagePathSharp } = image;
      return {
        id,
        thumbnailImagePathSharp: thumbnailImagePathSharp,
        pixelWidth: thumbnailImagePathSharp?.childImageSharp.gatsbyImageData
          .width as number,
      };
    }
  );

  const galleryItems = [...imageGalleryItems, ...videoGalleryItems];

  return (
    <Shell>
      <SEO
        locationOrigin={siteUrl}
        locationPathname={location.pathname}
        openGraphImagePath={
          exhibition.largeImagePathSharp.childImageSharp.gatsbyImageData.images
            .fallback?.src
        }
        openGraphImageWidth={
          exhibition.largeImagePathSharp.childImageSharp.gatsbyImageData.width
        }
        openGraphImageHeight={
          exhibition.largeImagePathSharp.childImageSharp.gatsbyImageData.height
        }
        title={`${title} - ${t('ArtExhibitions')}`}
        description={`${t('ArtExhibition')}: ${title}`}
      />
      <Content>
        <Container>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={10} md={9}>
              <Breadcrumbs
                root={{ link: Routes.Exhibitions, text: t('Exhibitions') }}
                current={{ text: title }}
              />
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
              >
                {image && (
                  <div
                    onClick={jumboClick}
                    tabIndex={1}
                    onKeyDown={(e) => e.key === 'Enter' && jumboClick()}
                  >
                    <GatsbyImage
                      image={image}
                      alt=""
                      objectFit="cover"
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                )}
              </Grid>
              <Heading align="center">{title}</Heading>
              <S.SubHeadingDate>{renderDate()}</S.SubHeadingDate>
              <p>{nl2br(bodyText)}</p>

              <S.Details>
                <InfoSection title={t('DateAndTime')} icon="calendar">
                  {nl2br(isEnglish ? enTimeDate : svTimeDate)}
                </InfoSection>
                {locationAnchorLink && (
                  <InfoSection title={t('Location')} icon="map">
                    <a href={locationAnchorLink} target="_blank">
                      {locationAnchorText || locationAnchorLink}
                    </a>
                  </InfoSection>
                )}
                {exhibitionWebsiteAnchorLink && (
                  <InfoSection title={t('ExhibitionersWebsite')} icon="website">
                    <a href={exhibitionWebsiteAnchorLink} target="_blank">
                      {exhibitionWebsiteAnchorText ||
                        exhibitionWebsiteAnchorLink}
                    </a>
                  </InfoSection>
                )}
              </S.Details>
              {typeof window !== 'undefined' &&
                (navigator.share || process.env.NODE_ENV === 'development') && (
                  <S.ShareAPIButtonContainer>
                    <Button
                      onClick={async () => {
                        try {
                          await navigator.share({ url: location.href });
                          typeof window !== 'undefined' &&
                            window.gtag('event', gtagEvents.shareExhibition, {
                              url: location.href,
                            });
                        } catch (err) {}
                      }}
                    >
                      <i className="lni-share" style={{ fontSize: '12px' }} />
                      <span>{t('ShareExhibition')}</span>
                    </Button>
                  </S.ShareAPIButtonContainer>
                )}
              <S.GalleryContainer>
                <Gallery
                  pullOut
                  items={galleryItems}
                  onGalleryItemClick={(index) => {
                    setCurrentImageIndex(index + 1);
                    openMediaModal();
                  }}
                />
              </S.GalleryContainer>
              <S.HyvorContainer>
                <Embed
                  language={language}
                  websiteId={HYVOR_TALK_WEBSITE_ID}
                  id={`${HYVOR_TALK_ID_PREFIX.exhibition}${exhibition.id}`}
                />
              </S.HyvorContainer>
            </Grid>
          </Grid>
        </Container>
      </Content>
      <MediaModal
        mediaItems={modalMediaItems}
        currentIndex={currentImageIndex}
        setCurrentIndex={setCurrentImageIndex}
        closeModal={closeMediaModal}
        isOpen={isMediaModalOpen}
      />
    </Shell>
  );
};

export default ExhibitionPage;

export const query = graphql`
  query ($language: String!, $slug: String!) {
    api {
      exhibition: exhibitionBySlug(slug: $slug) {
        id
        slug
        imagePath
        jumboImagePathSharp: imagePathSharp {
          childImageSharp {
            gatsbyImageData(
              height: 300
              width: 1000
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        largeImagePathSharp: imagePathSharp {
          childImageSharp {
            gatsbyImageData(
              height: 900
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        svTitle
        enTitle
        svBody
        enBody
        svTimeDate
        enTimeDate
        published
        locationAnchorText
        locationAnchorLink
        exhibitionWebsiteAnchorText
        exhibitionWebsiteAnchorLink
        startAt
        endAt
        youtubeVideoIds
        additionalExhibitionImages {
          id
          exhibitionID
          enDescription
          svDescription
          itemOrder
          imagePath
          thumbnailImagePathSharp: imagePathSharp {
            childImageSharp {
              gatsbyImageData(
                height: 210
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          largeImagePathSharp: imagePathSharp {
            childImageSharp {
              gatsbyImageData(
                height: 1000
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
